/*
 * 업무구분: 리크루팅
 * 화면 명: MSPRC253D
 * 화면 설명: 세미나현황 - 결과조회
 * 화면 접근권한: 지점장, 지역단스텝, 리크루팅본사스텝 (F110, F102, R100)
 * 작 성 일: 2022.06.03
 * 작 성 자: sh.park2022
 */
<template #scroll>
  <div>
    <ur-box-container alignV="start" componentid="ur_box_container_1110" direction="column">
      <mo-tab-box default-idx="1" primary class="ns-tabs-box-o">
        <!-- String index -->
        <mo-tab-label idx="1" @click="fn_TabEvent('01')">세미나기준</mo-tab-label>
        <!-- Complex label needs custom tooltip -->
        <mo-tab-label idx="2" @click="fn_TabEvent('02')">참석후보자기준</mo-tab-label>
        <!-- Contents... -->
        <mo-tab-content idx="1">
          <ur-box-container alignV="start" componentid="ur_box_container_2220" direction="column" class="ns-info-sum">
            <mo-list-item>
              <div class="list-item__contents">
                <div class="list-item__contents__title">
                  <span class="sum">총 {{ semClbyInqrList.length }}건</span>
                </div>
              </div>
            </mo-list-item>
          </ur-box-container>

          <ur-box-container v-if="!isNoData01" alignV="start" componentid="ur_box_container_2221" direction="column" class="ns-contract-list">
            <mo-list :list-data="semClbyInqrList">
              <template #list-item= "{item}">
                <mo-list-item>
                  <div class="list-item__contents">
                    <div class="list-item__contents__title">
                      <span class="name txtSkip">{{ item.semNm }}</span><!-- 세미나명 -->
                    </div>
                    <div class="list-item__contents__info">
                      <div class="txt_box">
                        <span>{{ item.dofOrgNm }}</span><em>|</em><!-- 지역단명 -->
                        <span>{{ item.hqOrgNm }}</span><!-- 본부명 -->
                      </div>
                    </div>
                    <div class="list-item__contents__info">
                      <div class="txt_box">
                        <span>{{ $bizUtil.dateDashFormat(item.semEfDd) }}</span><em>|</em><!-- 생년월일 -->
                        <span>{{ item.lectrNm }}</span><!-- 강사명 -->
                      </div>
                    </div>
                    <div class="list-item__contents__info">
                      <div class="txt_box">
                        <span>신청 {{ item.semAplNprsn }}</span><em>|</em>
                        <span>참석 {{ item.semAtdncNprsn }}</span><em>|</em>
                        <span>참석율 {{ item.semAtdncRaate }}%</span>
                      </div>
                    </div>
                  </div>
                </mo-list-item>
              </template>
            </mo-list>
          </ur-box-container>
          <!-- NoData 영역 ::start  -->
          <ur-box-container v-else alignV="start" componentid="ur_box_container_002" direction="column" class="ns-contract-list no-data">
            <mo-list-item>
              <div class="list-item__contents">
                <div class="list-item__contents__info inlineBox">
                  <span>데이터가 존재하지 않습니다.</span>
                </div>
              </div>
            </mo-list-item>
          </ur-box-container>
          <!-- NoData 영역 ::end  -->
        </mo-tab-content>

        <mo-tab-content idx="2">
          <ur-box-container alignV="start" componentid="ur_box_container_2222" direction="column" class="ns-info-sum">
              <mo-list-item>
              <div class="list-item__contents">
                <div class="list-item__contents__title">
                  <span>총 {{ cndtrClbyInqrList.length }}명</span>
                </div>
              </div>
            </mo-list-item>
          </ur-box-container>
          
          <ur-box-container v-if="!isNoData02" alignV="start" componentid="ur_box_container_002" direction="column" class="ns-contract-list center">
            <!-- horizontal -->
            <mo-list :list-data="cndtrClbyInqrList">
              <template #list-item= "{item}">
                <mo-list-item expandable ref="expItem">
                  <div class="list-item__contents">
                    <div class="list-item__contents__title">
                      <span class="name txtSkip">{{ item.cndtrNm }}</span>
                    </div>
                    <div class="list-item__contents__info">
                      <div class="txt_box">
                        <span>{{ $bizUtil.dateDashFormat(item.dob) }}</span><em>|</em> <!-- 생년월일 -->
                        <span>{{ item.sxcdNm === '남자'? '남' : '여' }}</span><em>|</em><!-- 성별 -->
                        <span>{{ item.qlfYn }}</span> <!-- 적격여부 -->
                      </div>
                    </div>
                    <div class="list-item__contents__info">
                      <div class="txt_box">
                        <span class="date">삭제예정일 <strong>{{ $bizUtil.dateDashFormat(item.deltParngDd) }}</strong></span>
                      </div>
                    </div>
                  </div>
                  <template v-slot:expand>
                    <div class="list-item__contents">
                      <div class="list-item__contents__info">
                        <span class="info">동의 유효기간</span>
                        <span class="info">{{ fn_GetRcce(item.recrCnsntYn, item.recrCnsntYmd) }}</span>
                      </div>
                      <div class="list-item__contents__info">
                        <span class="info">참석일</span>
                        <span class="info">{{ $bizUtil.dateDashFormat(item.semAtdncDd) }}</span>
                      </div>
                      <div class="list-item__contents__info">
                        <span class="info">추천FC(사번)</span>
                        <span class="info">{{ item.recmFcNm }}({{ item.recmFcEno }})</span>
                      </div>
                    </div>
                  </template>
                </mo-list-item>
              </template>
            </mo-list>
          </ur-box-container>
          <!-- NoData 영역 ::start  -->
          <ur-box-container v-else alignV="start" componentid="ur_box_container_002" direction="column" class="ns-contract-list no-data">
            <mo-list-item>
              <div class="list-item__contents">
                <div class="list-item__contents__info">
                  <span>{{ stateMsg }}</span>
                  <!-- <span>상세조회 조건을 설정해주세요.</span> -->
                  <!-- <span>데이터가 존재하지 않습니다.</span> -->
                </div>
              </div>
            </mo-list-item>
          </ur-box-container>
          <!-- NoData 영역 ::end  -->
        </mo-tab-content>
      </mo-tab-box>
    </ur-box-container> 
  </div>
</template>
<script>
import Screen from '~systems/mixin/screen'
import MSPBottomAlert from '@/ui/_cmm/components/MSPBottomAlert'

import MSPRC251P from '@/ui/rc/MSPRC251P.vue'

export default {
  name: 'MSPRC253D',
  screenId: 'MSPRC253D',
  mixins: [Screen],
  components: {
    MSPRC251P
  },
  props: {
    // 상세조회 입력값
    pData: {
      type: Object
    }
  },
  data() {
    return {
      title: '세미나현황-결과조회',
      stateMsg: '',
      userInfo: {}, // 사용자 정보

      tabIdx: '01',
      isSearchSemi: false, // 후보자명
      isSearchCust: false, // 세미나명

      semClbyInqrList: [], // 세미나기준 데이터 리스트
      cndtrClbyInqrList: [], // 후보자기준 데이터 리스트
      isFistCndtr: false, // 후보자기준 조회 시도 여부
      searchBase: 'seminar', // 조회시, 기준 체크용 (세미나기준: seminar, 참석후보자기준: cndrt)
      semEfMnt: '', // 세미나 실시월

      isNoData01: true, // 세미나기준 데이터 유무
      isNoData02: true  // 참석후보자기준 데이터 유무
    }
  },
  /************************************************************************************************
  * Life Cycle 함수 정의 영역
  ***********************************************************************************************/
  created() {
    this.userInfo = this.getStore('userInfo').getters.getUserInfo
    this.stateMsg = '상세조회 조건을 설정해주세요.'
  },
  mounted() {
    /** LOG 적재 Util **/
    this.$bizUtil.insSrnLog(this.$options.screenId)
  },
  /************************************************************************************************
   * watch 정의 영역
   ************************************************************************************************/
  watch: {
    pData() {
      if (!_.isEmpty(this.pData) && this.pData.tabIdx === '02') {
        if (this.searchBase === 'seminar') {
          this.fn_SelListAllSem()
        } else if (this.searchBase === 'cndrt') {
          this.fn_SelListAllCndtr()
        }
      }
    }
  },
  /************************************************************************************************
   * 사용자 함수 정의 영역
   ************************************************************************************************/
  methods: {
    /******************************************************************************
     * Function명 : fn_SelListAllSem
     * 설명       : 세미나별 조회
     ******************************************************************************/
    fn_SelListAllSem(callScreenId, efMnt) {
      const trnstId = 'txTSSRC33S1'
      const auth = 'S'
      const lv_Vm = this

      if (this.semEfMnt === efMnt) return

      let pParams = {}
      if (callScreenId === 'MSPRC250M') {
        pParams.hqOrgCd  =  this.userInfo.onpstHofOrgNo // 사업부
        pParams.dofOrgCd =  this.userInfo.onpstDofNo    // 지역단
        pParams.fofOrgCd =  this.userInfo.onpstFofOrgNo // 지점
        pParams.semEfMnt =  efMnt // 실시월(YYYYMM)
      } else {
        pParams.hqOrgCd  = this.pData.hofOrgCd, // 선택사업부
        pParams.dofOrgCd = this.pData.dofOrgCd, // 선택지역단
        pParams.fofOrgCd = this.pData.fofOrgCd, // 선택지점
        pParams.semEfMnt = this.pData.efMntStr  // 실시월(YYYYMM)
      }

      this.semEfMnt = efMnt
      this.isNoData01 = false
      this.post(lv_Vm, pParams, trnstId, auth)
        .then(function (response) {
          lv_Vm.semClbyInqrList = []

          if (!_.isEmpty(response.body) && !_.isEmpty(response.body.semClbyInqrList)) {
            lv_Vm.semClbyInqrList = response.body.semClbyInqrList
            lv_Vm.isNoData01 = false
          } else {
            lv_Vm.isNoData01 = true
          }
        })
        .catch(function (error) {
          // 시스템적인 오류가 발생하면, 일반적인 에러 메세지를 confirm으로 표시
          window.vue.error(error)
          lv_Vm.isNoData01 = true
        })
    },
    /******************************************************************************
     * Function명 : fn_SelListAllCndtr
     * 설명       : 후보자별 참석조회 조회
     ******************************************************************************/
    fn_SelListAllCndtr() {
      const trnstId = 'txTSSRC34S1'
      const auth = 'S'
      const lv_Vm = this

      let pParams = {
        cndtrNm:  this.pData.cndtrNm,   // 검색: 후보자명
        hqOrgCd:  this.pData.hofOrgCd, // 선택사업부
        dofOrgCd: this.pData.dofOrgCd, // 선택지역단
        fofOrgCd: this.pData.fofOrgCd, // 선택지점
        semEfMnt: this.pData.efMntStr, // 실시월(YYYYMM)
        semNm:    this.pData.semNm,    // 세미나명
        semYmd:   this.pData.seminarYmdTm.substr(0,8) // 세미나일자(YYYYMMdd)
      }

      // 초기화
      this.cndtrClbyInqrList = []
      this.isNoData02 = false

      this.post(lv_Vm, pParams, trnstId, auth)
        .then(function (response) {
          lv_Vm.isFistCndtr = true
          lv_Vm.stateMsg = '데이터가 존재하지 않습니다.'

          if (!_.isEmpty(response.body) && !_.isEmpty(response.body.cndtrClbyInqrList)) {
            lv_Vm.cndtrClbyInqrList = response.body.cndtrClbyInqrList
            
            // 후보자명 검색시, 필터링 진행
            if (!_.isEmpty(pParams.cndtrNm, response.body.cndtrClbyInqrList)) {
              lv_Vm.cndtrClbyInqrList = lv_Vm.filterCndtrNm(pParams.cndtrNm, response.body.cndtrClbyInqrList)
            }
          }

          if (!_.isEmpty(lv_Vm.cndtrClbyInqrList)) {
            lv_Vm.isNoData02 = false
          } else {
            lv_Vm.isNoData02 = true
          }
        })
        .catch(function (error) {
          // 시스템적인 오류가 발생하면, 일반적인 에러 메세지를 confirm으로 표시
          window.vue.error(error)
          lv_Vm.isNoData02 = true
        })
    },
    /******************************************************************************
     * Function명 : filterCndtrNm
     * 설명       : 검색: 후보자명으로 필터링 (검색한 후보자명 없을시 => No Data)
     ******************************************************************************/
    filterCndtrNm(cndtrNm, cndtrClbyInqrList) {
      let rtnList = []

      cndtrClbyInqrList.forEach(item => {
        if (item.custNm === cndtrNm) {
          rtnList.push(item)
        }
      })

      if (rtnList.length > 0) {
        this.isNoData02 = false
      } else {
        this.isNoData02 = true
      }
      return rtnList
    },
    /********************************************************************************
     * Function명 : fn_TabEvent
     * 설명       : 세미나기준/참석후보자기준 탭 변경시 전역변수 변경
     *              > 세미나기준-상세조회 팝업에서 후보자명, 세미나List 보여지지 않음
     ********************************************************************************/
    fn_TabEvent(tabId) {
      this.tabIdx = tabId

      // 세미나기준
      if (tabId === '01') {
        this.isSearchSemi = false
        this.isSearchCust = false
        this.searchBase = 'seminar'
      // 참석후보자기준
      } else if (tabId === '02') {
        this.isSearchSemi = true
        this.isSearchCust = true
        this.searchBase = 'cndrt'

		// 첫 진입시 상세조회 안내 팝업 Show
        if (!this.isFistCndtr) {
          this.fn_BottomAlert()
        }
      }
      this.$emit('changBaseSearch', tabId)
    },
    /******************************************************************************
     * Function명 : fn_GetRcce
     * 설명        : 리크루팅 끝나기 남은 일수
     * recrCnsntYn : 리크루팅 동의 여부
     * recrCnsntEnd: recrCnsntEnd
     ******************************************************************************/
    fn_GetRcce(recrCnsntYn, recrCnsntYmd) {
      let rtnValu = ''

      // 동의 유효기간까지 남은 일자가 100일 이상이라면, Y D-99+
      // 아니라면, 예시) Y D-55
      if (recrCnsntYmd  >= 0 && recrCnsntYmd  <100) {
        rtnValu = recrCnsntYn + " D-" + recrCnsntYmd
      } else if (recrCnsntYmd >= 100) {
        rtnValu = recrCnsntYn + " D-99+"
      } else {
        rtnValu = recrCnsntYn
      }

      return rtnValu
    },
    /******************************************************************************
     * Function명 : fn_BottomAlert
     * 설명       : 단순알림 팝업 호출
     ******************************************************************************/
    fn_BottomAlert () {
      const lv_Vm = this
      this.lv_AlertPopup = this.$bottomModal.open(MSPBottomAlert, {
        properties: {
          single: true,
          title: '알림',
          content: '세미나를 선택해주세요.'
        },
        listeners: {
          onPopupConfirm: () => {
            lv_Vm.$emit('semCnfm') // 상세조회팝업 Show
            this.$bottomModal.close(lv_Vm.lv_AlertPopup);
          },
          onPopupClose: () => {
            this.$bottomModal.close(lv_Vm.lv_AlertPopup);
          }
        }
      },
        {properties: {preventTouchClose : false}
      })
    }
  }
}
</script>