/*
 * 업무구분: 리크루팅
 * 화면 명: MSPRC250M
 * 화면 설명: 세미나현황
 * 화면 접근권한: 지점장, 지역단스텝, 리크루팅본사스텝 (F110, F102, R100)
 * 작 성 일: 2022.06.03
 * 작 성 자: sh.park2022
 */
<template>
  <ur-page-container title="세미나현황" :show-title="false">
    <ur-box-container direction="column" alignV="start">

      <div class="ns-sliding-topbar">
        <mo-collapsing-header-view headerColor="#fff">
          <template #fixed="{scrollRate}">
            <!-- 헤더 타이틀 영역 ::start -->
            <mo-top-bar color="primary" scroll-shadow scroll-target="scroll_collapsingHeaderView">
              <div slot="nav" class="icon-wrapper">
                  <mo-icon icon-size="16px" borderless @click="fn_HistoryBack">previous</mo-icon>
              </div>
              {{scrollRate &gt; 0.5 ? '세미나현황' : '세미나현황'}}

              <div slot="action" class="icon-wrapper">
                <mo-button class="ns-btn-seminar" :class="isModalShow? 'on' : ''" v-show="tabIdx === '01'" @click="fn_ModalOpen()">참석현황</mo-button>
                <mo-icon icon-size="16px" type="picto" @click="fn_OpenPop">search</mo-icon>
              </div>
            </mo-top-bar>
            <!-- 헤더 타이틀 영역 ::end -->
          </template>
          
          <!-- collapsing 영역 -->
          <template #collapsing>
            <!-- 상단 콘텐츠 영역 기타 배너 영역 -->  
            <div style="height:1px"></div>
            <!-- 상단 콘텐츠 영역 //-->
          </template>
          
          <!-- stickey 영역 -->
          <template #sticky>
            <!-- Tab 영역 -->
            <mo-tab-box default-idx="01" primary class="ns-move-tab-box">
              <mo-tab-label idx="01" @click="fn_TabClick('01')">결과입력</mo-tab-label>
              <mo-tab-label idx="02" @click="fn_TabClick('02')">결과조회</mo-tab-label>
            </mo-tab-box>

            <!-- <mo-checkbox v-show="rCSemAttdListVO.length > 0" v-model="isOneOrMoreCheck" @click="fn_CheckAll()"></mo-checkbox> -->
            <ur-box-container v-if="tabIdx === '01'" alignV="start" componentid="ur_box_container_001" direction="column" class="ns-check-sum">
              <mo-list-item>
                <mo-checkbox v-show="semAttdTot > 0" v-model="isChkAll" @input="fn_252DcheckAll" :disabled="isFofRol"></mo-checkbox>
                <div class="list-item__contents">
                  <div class="list-item__contents__title">
                    <span class="sum">총 {{ semAttdTot }}명</span>
                  </div>
                </div>
              </mo-list-item>
            </ur-box-container>
          </template>
 
          <template #scroll>
              <!-- 결과입력 content -->
              <MSPRC252D ref="refRC252D" v-show="tabIdx === '01'" :pData="pData252"
                        @semResult="fn_SetAttnPnsta" @changeAtdnc="fn_ChangeAtdnc"
                        @clickChkAll="fn_ChkAllHandle"></MSPRC252D>
              <!-- 결과조회 content -->
              <MSPRC253D ref="refRC253D" v-show="tabIdx === '02'" :pData="pData253"
                        @changBaseSearch="fn_SetBaseSearch" @semCnfm="fn_OpenPop"></MSPRC253D>
          </template>

        </mo-collapsing-header-view>    
      </div>

      <!-- 참석현황 Modal ::start 딤처리요청 22.07.27 DEF_167-->
      <div v-show="tabIdx === '01'" class="ns-bottom-sheet ns-bottom-popup-m">
        <mo-bottom-sheet ref="refAttnPnstaModal" @closed="fn_ModalClose">
            <template v-slot:title>참석현황</template>
            <div class="ns-seminar-info">
              <ul>
                <li>
                  <span>신청인원</span>
                  <strong>{{ semAtdncAplFgr }}명</strong>
                </li>
                <li>
                  <span>참석인원</span>
                  <strong>{{ semAtdncFgr }}명</strong>
                </li>
                <li>
                  <span>참석률</span>
                  <strong>{{ semAtdncRt }}%</strong>
                </li>
              </ul>
            </div>
            <template v-slot:action>
              <div class="ns-btn-round-area">
                <mo-button class="ns-btn-round blue2 btn-one" v-is-enable="{acl: ['I']}" @click="fn_SendResult" :disabled="isDofRolYn !== 'Y' || isOverTime">결과전송</mo-button>
              </div>
            </template>
        </mo-bottom-sheet>
        <!-- Toast -->
        <mo-snackbar ref="snackbar" :duration="3000" />
      </div>
      <!-- 참석현황 Modal ::end -->
    </ur-box-container>
  </ur-page-container>
</template>
<script>
import moment from 'moment'
import Screen from '~systems/mixin/screen'
import MSPBottomAlert from '@/ui/_cmm/components/MSPBottomAlert'

import MSPRC251P from '@/ui/rc/MSPRC251P.vue'
import MSPRC252D from '@/ui/rc/MSPRC252D.vue'
import MSPRC253D from '@/ui/rc/MSPRC253D.vue'

export default {
  name: 'MSPRC250M',
  screenId: 'MSPRC250M',
  mixins: [Screen],
  components: {
    MSPRC251P,
    MSPRC252D,
    MSPRC253D
  },
  data() {
    return {
      title: '',
      userInfo: {}, // 로그인 사번 정보
      isDofRolYn: '', // 지점역할여부
      isFofRol: false, // 지점장 여부

      popup251: {}, // MSPRC251P
      rCSemAttdListVO: [],
      semAttdTot: 0,
      tabIdx: '01', // 결과입력 / 결과조회 탭
      searchTabIdx: '01', // 결과조회 - 세미나기준 / 참석후보자기준 탭

      pData252: {}, // 결과입력(MSPRC252D) 파라미터
      pData253: {}, // 결과조회(MSPRC253D) 파라미터
      pOrgData250M: {}, // 최근 조직 조회 데이터
      lv_AlertPopup: {},

      pEfMntStr: '', // 입과월
      cndtrNm: '',   // 후보자명
      pSeminarYmdTm: '', // 세미나일정시각
      semAtdncAplFgr: 0, // 신청인원
      semAtdncFgr: 0,    // 참석인원
      semAtdncRt: 0,     // 참석률

      isChkAll: false, // 252D 전체 체크여부
      isOverTime: true,
      isModalShow: false
    }
  },
  /************************************************************************************************
  * Life Cycle 함수 정의 영역
  ************************************************************************************************/
  mounted() {
    const lv_Vm = this
    /** LOG 적재 Util **/
    this.$bizUtil.insSrnLog(this.$options.screenId)

    // 바텀 메뉴 보임 처리
    lv_Vm.$BottomManager.fn_SetBottomVisible(true)

    this.userInfo = this.getStore('userInfo').getters.getUserInfo
    this.isDofRolYn = this.userInfo.isDofRolYn // 지점역할여부

    // 지점장일경우, 체크박스 비활성화
    if (this.userInfo.userRoleList.indexOf('F110') > -1) {
      this.isFofRol = true
    }

    this.fn_BottomAlert()
  },
  /************************************************************************************************
   * 사용자 함수 정의 영역
  ************************************************************************************************/
  methods: {
    /******************************************************************************
     * Function명 : fn_TabClick
     * 설명       : 결과입력/결과조회 탭 클릭 이벤트
     * 탭 변경시, 참석현황 Modal Close
     * ******************************************************************************/
    fn_TabClick(tabIdx) {
      this.tabIdx = tabIdx
      let efMnt = this.pEfMntStr
      if (tabIdx === '02') {
        if (efMnt === '') {
          efMnt = moment(new Date()).format('YYYYMM')
        }
        this.$refs.refRC253D.fn_SelListAllSem('MSPRC250M', efMnt)
      }
      this.fn_ModalClose()
    },
    /******************************************************************************
     * Function명 : fn_OpenPop
     * 설명       : 상세조회 팝업 호출
     * listeners: function 등록후, 팝업에서 emit시 실행됩니다.
     ******************************************************************************/
    fn_OpenPop() {
      const lv_Vm = this
      let popOption = this.fn_SetPopOption()

      this.popup251 = this.$bottomModal.open(MSPRC251P,{
        properties: {
          title: '상세조회', // 팝업의 타이틀 셋팅
          isSearchCust: popOption.isSearchCust, // 팝업-후보자명
          isSearchSemi: popOption.isSearchSemi, // 팝업-세미나명
          pOrgData250M: this.pOrgData250M, // 최근 조직 조회 데이터
          pEfMntStr: this.pEfMntStr, // 실시월(YYYYMM)
          pSeminarYmdTm: this.pSeminarYmdTm, // 세미나일정(YYYYMMddHHmm)
          pTabIdx: this.tabIdx, // 선택된 탭 index
          pSearchTabIdx: this.searchTabIdx
        },
        // 팝업 닫히면서 실행되는 함수
        listeners: {
          // 결과입력에서 조회시 실행
          onPopupSearch: (pData) => {
            lv_Vm.fn_PopupCallback(pData)
          }
        }
      })
    },
    /******************************************************************************
     * Function명 : fn_PopupCallback
     * 설명       : 상세조회 팝업 닫히면서 실행됩니다.
     * tabIdx : 결과입력: 01 / 결과조회: 02
     ******************************************************************************/
    fn_PopupCallback(pData) {
      // 결과입력-조회팝업 에서 조회
      if (pData.tabIdx === '01') {
        this.pData252 = pData
      // 결과조회-조회팝업 에서 조회
      } else if (pData.tabIdx === '02') {
        this.pData253 = pData
      }
      this.$bottomModal.close(this.popup251)

      // 마지막 조직 조회 데이터 셋팅
      this.pOrgData250M = pData.pOrgData
      // 공통 파마리터 셋팅
      this.pEfMntStr = pData.efMntStr
      this.pSeminarYmdTm = pData.seminarYmdTm // 세미나일정시각
      this.cndtrNm = pData.cndtrNm  // 검색: 후보자명
    },
    /******************************************************************************
     * Function명 : fn_SetPopOption
     * 설명       : 팝업 후보자명/세미나명 보여줄지 여부
     * 결과조회-세미나기준 에서 팝업 호출시, 후보자명, 세미나명을 보여주지 않습니다.
     ******************************************************************************/
    fn_SetPopOption() {
      let isSearchCust = true
      let isSearchSemi = true

       if (this.tabIdx === '02') {
        isSearchCust = this.$refs.refRC253D.isSearchCust // 후보자명 검색 form
        isSearchSemi = this.$refs.refRC253D.isSearchSemi // 세미나 검색 form
      }
      return {isSearchCust, isSearchSemi}
    },
    /******************************************************************************
     * Function명 : fn_ModalOpen / fn_ModalClose
     * 설명       : 참석현황 Modal Open/Close
     ******************************************************************************/
    fn_ModalOpen() {
      this.isModalShow = true
      this.$refs.refAttnPnstaModal.open()
    },
    fn_ModalClose() {
      this.isModalShow = false
      this.$refs.refAttnPnstaModal.close()
    },
    /******************************************************************************
     * Function명 : fn_HistoryBack
     * 설명       : 이전 화면으로 전환
     ******************************************************************************/
    fn_HistoryBack() {
      this.$router.go(-1)
    },
    /******************************************************************************
     * Function명 : fn_SendResult
     * 설명       : 결과전송 선택한 후보자들 참석여부 업데이트
     ******************************************************************************/
    fn_SendResult() {
      this.rCSemAttdListVO = this.$refs.refRC252D.rCSemAttdListVO

      let list = []
      this.rCSemAttdListVO.forEach((item) => {
        let vo = {
          dofOrgNo:   item.semDofOrgNo,  // 세미나지역단조직번호
          semTypCd:   item.semTypCd,     // 세미나유형코드
          semPlnYmd:  item.semPlnYmd,    // 세미나일자
          chnlCustId: item.chnlCustId,   // 채널고객ID
          semAtdncYn: item.semAtdncYn    // 세미나참석여부
        }
        list.push(vo)
      })

      const lv_Vm = this
      const trnstId = 'txTSSRC31U1'
      const auth = 'S'
      let pParams = {}
      pParams.rCJobSemAttdListVO = list

      this.post(lv_Vm, pParams, trnstId, auth)
        .then(function (response) {
          if (!_.isEmpty(response.body)) {
            lv_Vm.fn_ModalClose()
            lv_Vm.isChkAll = false
            lv_Vm.$refs.snackbar.show({text: '세미나 참석현황의 결과전송이 완료되었습니다.'});
          } else {
            lv_Vm.$refs.snackbar.show({text: 'respons 데이터 없습니다.'});
          }
          lv_Vm.fn_ModalClose()
        })
        .catch(function (error) {
          // 시스템적인 오류가 발생시
          lv_Vm.fn_ModalClose()
          window.vue.error(error)
          lv_Vm.$refs.snackbar.show({text: '결과전송을 실패했습니다.'});
        })
    },
    /******************************************************************************
     * Function명 : fn_SetAttnPnsta
     * 설명       : 참석현황 데이터 셋팅   SetAttnPnsta
     * 세미나별 참석자 조회하면서, 실행됩니다.
     ******************************************************************************/
    fn_SetAttnPnsta(pData) {
      this.isOverTime = this.$refs.refRC252D.isOverTime
      
      if (_.isEmpty(pData.resData.rCSemAttdListVO)) { // 값이 없다면, 0으로 셋팅
        this.semAtdncAplFgr = '0'
        this.semAtdncFgr = '0'
        this.semAtdncRt = '0'
        this.semAttdTot = pData.semAttdTot
        return
      }
      this.semAtdncAplFgr = pData.resData.semAtdncAplFgr  // 신청인원
      this.semAtdncFgr = pData.resData.semAtdncFgr        // 참석인원
      this.semAtdncRt = Math.round(pData.resData.semAtdncFgr / pData.resData.semAtdncAplFgr * 100) // 참석률
      this.semAttdTot = pData.semAttdTot
    },
    /******************************************************************************
     * Function명 : fn_ChangeAtdnc
     * 설명       : 참석률 셋팅 함수
     ******************************************************************************/
    fn_ChangeAtdnc(pData) {
      this.semAtdncFgr = pData.semAtdncFgr
      this.semAtdncRt = Math.round(pData.semAtdncFgr / this.semAtdncAplFgr * 100) // 참석률
    },
    /******************************************************************************
     * Function명 : fn_SetBaseSearch
     * 설명       : 결과조회 - 세미나기준 / 참석후보자기준 탭 셋팅
     *              세미나기준: 상세조회 팝업에서, 지점선택하지 않음
     ******************************************************************************/
    fn_SetBaseSearch(pData) {
      this.searchTabIdx = pData
      // console.log('BaseSearch', pData)
    },
    /******************************************************************************
     * Function명 : fn_252DcheckAll
     * 설명       : 결과입력, 후보자 전체선택
     ******************************************************************************/
    fn_252DcheckAll() {
      this.$refs.refRC252D.fn_CheckAll(this.isChkAll)
    },
    /******************************************************************************
     * Function명 : fn_ChkAllHandle
     * 설명       : 후보자 개별선택시, 전체선택 핸들링 함수
     ******************************************************************************/
    fn_ChkAllHandle(pData) {
      this.isChkAll = pData
    },
    /******************************************************************************
     * Function명 : fn_BottomAlert
     * 설명       : 단순알림 팝업 호출
     ******************************************************************************/
    fn_BottomAlert () {
      const lv_Vm = this
      this.lv_AlertPopup = this.$bottomModal.open(MSPBottomAlert, {
        properties: {
          single: true,
          title: '알림',
          content: '세미나를 선택해주세요.'
        },
        listeners: {
          onPopupConfirm: () => {
            lv_Vm.fn_OpenPop()
            this.$bottomModal.close(lv_Vm.lv_AlertPopup);
          },
          onPopupClose: () => {
            this.$bottomModal.close(lv_Vm.lv_AlertPopup);
          }
        }
      },
        {properties: {preventTouchClose : false}
      })
    }
  }
}
</script>
