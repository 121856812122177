/*
 * 업무구분: 리크루팅
 * 화면 명: MSPRC252D
 * 화면 설명: 세미나현황 -  결과입력
 * 화면 접근권한: 지점장, 지역단스텝, 리크루팅본사스텝 (F110, F102, R100)
 * 작 성 일: 2022.06.03
 * 작 성 자: sh.park2022
 */
<template>
  <div>
    <ur-box-container v-if="!isNoData" alignV="start" componentid="ur_box_container_002" direction="column" class="ns-check-list">
      <mo-list-item v-for="item in rCSemAttdListVO" :key="item.chnlCustId">
        <div class="list-item__contents">
          <div class="list-item__contents__title">
            <mo-checkbox v-model="item.checked" @input="fn_CheckSelectedItemCount" :disabled="isFofRol"/>
            <span class="name txtSkip">{{ item.custNm }}</span><!-- 후보자명 -->
            <!-- class: 미참석(gray) / 참석(blue) -->
            <mo-badge class="badge-sample-badge" :class="item.semAtdncYn !== 'Y' ? 'gray' : 'blue'" text="본인" shape="status">{{ item.semAtdncYn !== 'Y' ? '미참석' : '참석' }}</mo-badge>
          </div>
          <div class="list-item__contents__info inlineBox">
            <div class="txt_box">
              <span>{{ $bizUtil.dateDashFormat(item.dob) }}</span><em>|</em><!-- 생년월일 -->
              <span>{{ item.sxdsCd === '1' ? '여' : '남' }}</span><em>|</em><!-- 성별 '1':여자 / '2':남자-->
              <span>{{ item.cnstlNm }}({{ item.cnstlNo }})</span><!-- 추천FC명(FC사번) -->
            </div>
          </div>
          <div class="list-item__contents__info inlineBox">
            <div class="txt_box">
              <span>{{ item.fofNm }}</span><em>|</em><!-- 지점명 -->
              <span>{{ item.semNm }}</span><!-- 세미나명 -->
            </div>
          </div>
        </div>
      </mo-list-item>
      <div class="autoHeight" :class="isAutoHieght ? 'on' : ''">
      </div>
    </ur-box-container>
    <!-- NoData 영역 ::start  -->
    <ur-box-container v-else alignV="start" componentid="ur_box_container_002" direction="column" class="ns-check-list no-data">
      <mo-list-item>
        <div class="list-item__contents">
          <div class="list-item__contents__info">
            <span>{{ stateMsg }}</span>
            <!-- <span>상세조회 조건을 설정해주세요.</span> -->
            <!-- <span>데이터가 존재하지 않습니다.</span> -->
          </div>
        </div>
      </mo-list-item>
    </ur-box-container>
    <!-- NoData 영역 ::end  -->

    <!-- 참석/미참석 Modal :start -->
    <div class="ns-bottom-sheet ns-style4">
      <mo-bottom-sheet ref="refAttnYnModal">
          <template v-slot:title></template>
          <p class="ns-seminar-txt">
            선택된 후보자 {{ selectItems.length }}명의 참석여부를<br>입력해주세요.
          </p>
          <template v-slot:action>
            <div class="ns-btn-relative">
              <div class="relative-div">
                <mo-button class="ns-btn-round white" @click="fn_ModalClose('N')" v-is-enable="{acl: ['I']}" :disabled="isDofRolYn !== 'Y' || isOverTime">미참석</mo-button>
                <mo-button class="ns-btn-round blue" @click="fn_ModalClose('Y')" v-is-enable="{acl: ['I']}" :disabled="isDofRolYn !== 'Y' || isOverTime">참석</mo-button>
              </div>
            </div>
          </template>
      </mo-bottom-sheet>
      <mo-snackbar ref="snackbar" :duration="3000" />
    </div>
    <!-- 참석/미참석 Modal :end -->
  </div>
</template>
<script>
import Screen from '~systems/mixin/screen'
import moment from 'moment'
import MSPRC251P from '@/ui/rc/MSPRC251P.vue'
import MSPCM117P from '@/ui/cm/MSPCM117P.vue' // 고객 상세팝업

export default {
  name: 'MSPRC252D',
  screenId: 'MSPRC252D',
  mixins: [Screen],
  components: {
    MSPRC251P,
    MSPCM117P // 고객 상세팝업
  },
  props: {
    // 상세조회 입력값
    pData: {
      type: Object
    }
  },
  data() {
    return {
      title: '세미나현황-결과입력',
      stateMsg: '',

      userInfo: {}, // 로그인 사번 정보
      isDofRolYn: '', // 지점역할여부
      isFofRol: false, // 지점장 여부

      isScrollActive: '', // scroll Class Css 적용
      isAutoHieght: false,

      origRCSemAttdListVO: [],
      rCSemAttdListVO: [],
      popupCM117: {},
      isNoData: true,

      selectItems: [],
      isOneOrMoreCheck: false,
      semAttdTot: 0, // 전체 참석인원
      isAtdncY: false,
      isAtdncN: false,
      isOverTime: false, // 참석/미참석 변경 가능 여부

      // -- 참석현황 -- //
      semAtdncAplFgr: 0, // 세미나신청인원
      semAtdncFgr: 0, // 세미나참석인원
      semAtdncRt: 0 // 참석률
      
    }
  },
  /************************************************************************************************
  * Life Cycle 함수 정의 영역
  ************************************************************************************************/
  created() {
    this.userInfo = this.getStore('userInfo').getters.getUserInfo
    this.isDofRolYn = this.userInfo.isDofRolYn // 지점역할여부
    
    this.fn_Init()
  },
  mounted() {
    /** LOG 적재 Util **/
    this.$bizUtil.insSrnLog(this.$options.screenId)
  },
  watch: {
    // 상세조회 팝업 return Data (조회조건 변경시에 호출)
    pData() {
      if (!_.isEmpty(this.pData) && this.pData.tabIdx === '01') {
        this.fn_SeminarAllCndtr()
      }
    }
  },
  /************************************************************************************************
   * 사용자 함수 정의 영역
  ************************************************************************************************/
  methods: {
    fn_Init() {
      /** 초기 진입시, NoData 화면 **/
      this.stateMsg = '상세조회 조건을 설정해주세요.'
      this.userInfo = this.getStore('userInfo').getters.getUserInfo

      // 지점장일경우, 체크박스 비활성화
      if (this.userInfo.userRoleList.indexOf('F110') > -1) {
        this.isFofRol = true
      }
    },
    /******************************************************************************
     * Function명 : fn_SeminarAllCndtr
     * 설명       : 세미나별 참석자 조회
     *              세미나 해당 월까지만 결과전송 가능 -> 조회된 세미나명의 세미나일자(semPlnYm)
     *              세미나 결과전송 기한이 지났습니다.\n(세미나 실시일 기준 해당월 말일까지 가능합니다.)
     ******************************************************************************/
    fn_SeminarAllCndtr() {
      const trnstId = 'txTSSRC31S1'
      const auth = 'S'
      const lv_Vm = this

      let pParams = {
        cnstlNo: this.userInfo.cnsltNo,// 본인 사번
        hofOrgNo: this.pData.hofOrgCd, // 선택사업부
        dofOrgNo: this.pData.dofOrgCd, // 선택지역단
        fofOrgNo: this.pData.fofOrgCd, // 선택지점
        semPlnYmd: this.pData.seminarYmdTm.substring(0,8), // 세미나일자(YYYYMMdd)
        semTypCd: this.pData.semTypCd
      }

      this.isNoData = false
      this.isScrollActive = ''

      this.fn_CheckOverTime(pParams.semPlnYmd) // 조회 할 세미나가 기한이 지난 세미나 체크

      this.post(lv_Vm, pParams, trnstId, auth)
        .then(function (response) {
          lv_Vm.isOneOrMoreCheck = false
          lv_Vm.rCSemAttdListVO = response.body.rCSemAttdListVO
          lv_Vm.stateMsg = '데이터가 존재하지 않습니다.'

          if (!_.isEmpty(response.body) && !_.isEmpty(response.body.rCSemAttdListVO)) {
            lv_Vm.fn_SetOrignVo(response.body.rCSemAttdListVO)

            // 참석현황 데이터 셋팅
            lv_Vm.semAtdncAplFgr = response.body.semAtdncAplFgr // 세미나신청인원
            lv_Vm.semAtdncFgr = response.body.semAtdncFgr // 세미나참석인원
            lv_Vm.semAtdncRt = Math.round(lv_Vm.semAtdncFgr / lv_Vm.semAtdncAplFgr * 100) // 참석률

            // 조회조건 지점이 있으면 필터링
            if (lv_Vm.pData.fofOrgCd !== '0' && lv_Vm.pData.fofOrgCd !== '') {
              lv_Vm.rCSemAttdListVO = lv_Vm.rCSemAttdListVO.filter(item => item.fofOrgNo === lv_Vm.pData.fofOrgCd)
            }
            // 조회조건 고객명이 있으면 필터링
            if (!_.isEmpty(lv_Vm.pData.cndtrNm)) {
              lv_Vm.rCSemAttdListVO = lv_Vm.rCSemAttdListVO.filter(item => item.custNm.indexOf(lv_Vm.pData.cndtrNm.trim()) > -1)
            }

            if (!_.isEmpty(lv_Vm.rCSemAttdListVO)) {
              lv_Vm.isNoData = false
            } else {
              lv_Vm.isNoData = true
            }

            lv_Vm.semAttdTot = lv_Vm.rCSemAttdListVO.length
          } else {
            // 초기화
            lv_Vm.semAttdTot = 0
            lv_Vm.rCSemAttdListVO = []
            lv_Vm.origRCSemAttdListVO = []
            lv_Vm.isNoData = true
            lv_Vm.isOverTime = true
          }
          lv_Vm.$refs.refAttnYnModal.close()
          lv_Vm.$emit('clickChkAll', lv_Vm.isOneOrMoreCheck)
          lv_Vm.$emit('semResult', {resData: response.body, semAttdTot: lv_Vm.semAttdTot})
        })
        .catch(function (error) {
          // 시스템적인 오류가 발생하면, 일반적인 에러 메세지를 confirm으로 표시
          window.vue.error(error)
          lv_Vm.isNoData = true
        })
    },
    /******************************************************************************
     * Function명 : fn_CheckOverTime
     * 설명       : 조회 할 세미나가 기한이 지난 세미나 체크
     *              세미나 해당 월까지만 결과전송 가능 -> 조회된 세미나명의 세미나일자(semPlnYm)
     *              세미나 결과전송 기한이 지났습니다.\n(세미나 실시일 기준 해당월 말일까지 가능합니다.)
     ******************************************************************************/
    fn_CheckOverTime(seminarYmd) {
      let nowYymm = moment(new Date()).format('YYYYMM') // 현재월
      let seminarYymm = seminarYmd.substring(0,6) // 세미나 실시월(YYYYMM)

      if (seminarYymm >= nowYymm) {
        this.isOverTime = false
      } else {
        this.isOverTime = true
      }
    },
    /******************************************************************************
     * Function명 : fn_SetOrignVo
     * 설명       : 참석데이터 원본 셋팅
     * origRCSemAttdListVO: 채널고객ID, 세미나참석여부 셋팅
     *                        세미나별 참석자 데이터로 셋팅
     ******************************************************************************/
    fn_SetOrignVo(rCSemAttdListVO) {
      const lv_Vm = this

      rCSemAttdListVO.forEach(item=>{
        lv_Vm.origRCSemAttdListVO.push({chnlCustId: item.chnlCustId, semAtdncYn: item.semAtdncYn})
      })
    },
    /******************************************************************************
     * Function명 : fn_CheckSelectedItemCount
     * 설명       : 체크박스 클릭 핸들러
     *             - 리스트 中 선택한 후보자 셋팅 
     ******************************************************************************/
    fn_CheckSelectedItemCount() {
      this.$emit('scrollEvent')
      this.isOneOrMoreCheck = false // 매번 초기화 후, 체크
      this.selectItems = this.rCSemAttdListVO.filter(item => item.checked)
      
      // 선택된 참석 후보자 리스트
      let selectAttnItems = this.selectItems.filter(item=>{
          if (item.semAtdncYn === 'Y') {
              return item
          }
      })
      if (this.selectItems.length > 0) {
        if (selectAttnItems.length === this.selectItems.length) {
          // 모두 참석
          this.isAtdncY = true
          this.isAtdncN = false
        } else if (selectAttnItems.length < 1) {
          // 모두 미참석
          this.isAtdncY = false
          this.isAtdncN = true
        } else {
          // 참석/미참석
          this.isAtdncY = true
          this.isAtdncN = true
        }
      }

      // 전체선택 체크
      if (this.selectItems.length === this.rCSemAttdListVO.length) {
        this.isOneOrMoreCheck = true
      } else {
        this.isOneOrMoreCheck = false
      }
      this.$emit('clickChkAll', this.isOneOrMoreCheck)

      // 체크 시, BottomSheet 컨트롤 (BottomSheet: 참석/미참석 Sheet)
      if (this.selectItems.length > 0) {
        this.fn_ModalOpen()
      } else {
        this.fn_ModalClose()
      }
    },
    /******************************************************************************
     * Function명 : fn_CheckChngAttn
     * 설명       : 선택 후보자들 참석여부 변경시, 변경값으로 셋팅
     ******************************************************************************/
    fn_CheckChngAttn() {
      const lv_Vm = this
      let rtnValu = false

      this.origRCSemAttdListVO.forEach(item=>{
        for (let n in lv_Vm.selectItems) {
          if (item.chnlCustId === lv_Vm.selectItems[n].chnlCustId) {
            if (item.semAtdncYn !== lv_Vm.selectItems[n].semAtdncYn) {
              rtnValu = true // 참석여부 변경 체크(리턴값)
              return
            }
          }
        }
      })

      return rtnValu
    },
    /******************************************************************************
     * Function명 : fn_CheckAll
     * 설명       : 조회목록 전체선택
     ******************************************************************************/
    fn_CheckAll(isChkAll) {
      this.isOneOrMoreCheck = isChkAll

      this.$nextTick(() => {
        for (let n in this.rCSemAttdListVO) {
          if (this.isOneOrMoreCheck) {
            this.rCSemAttdListVO[n].checked = true
          } else {
            this.rCSemAttdListVO[n].checked = false
          }
        }
        this.fn_CheckSelectedItemCount()
      })
    },
    /******************************************************************************
     * Function명 : fn_ModalOpen() / fn_ModalClose()
     * 설명       : fn_ModalClose
     *                > 선택한 후보자들 참석여부 셋팅후, Close 진행
     ******************************************************************************/
    fn_ModalOpen() { 
      // this.$refs.refAttnYnModal.open()
      this.isAutoHieght = true
      this.$bizUtil.showCustomBottomSheet(this,this.$refs.refAttnYnModal, true)
      this.isScrollActive = 'Y'
    },
    fn_ModalClose(semAtdncYn) {
      const lv_Vm = this
      let snacMsg = '선택된 후보자 '+this.selectItems.length+'명이 세미나에 '

      if (semAtdncYn === 'Y') { // 참석
        snacMsg = snacMsg + '참석하였습니다.'
        this.selectItems.forEach(item => {
          item.semAtdncYn = 'Y'
          lv_Vm.semAtdncFgr = Number(lv_Vm.semAtdncFgr) + 1 
        })
      } else { // 미참석
        snacMsg = snacMsg + '미참석하였습니다.'
        this.selectItems.forEach(item => {
          item.semAtdncYn = 'N'
          lv_Vm.semAtdncFgr = Number(lv_Vm.semAtdncFgr) - 1 
        })
      }

      // Modal 닫히면서, snackbar 노출
      this.isScrollActive = ''      
      // this.$refs.refAttnYnModal.close()
      this.$bizUtil.showCustomBottomSheet(this,this.$refs.refAttnYnModal, false)
      this.isAutoHieght = false
      if (!_.isEmpty(this.selectItems)) {
        this.isOneOrMoreCheck = false
        for (let n in this.rCSemAttdListVO) {
            this.rCSemAttdListVO[n].checked = false
        }
        this.fn_CheckChngAttn()
        this.$emit('changeAtdnc',{semAtdncFgr: this.semAtdncFgr})
        this.$emit('clickChkAll', this.isOneOrMoreCheck)
        this.$refs.snackbar.show({text: snacMsg});
      }
    }

  }
}
</script>
