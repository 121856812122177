/*
 * 업무구분: 리크루팅
 * 화 면 명: MSPRC251P
 * 화면 설명: 세미나현황 - 상세조회
 * 작 성 일: 2022.06.08
 * 작 성 자: sh.park2022
 */

<template>
  <ur-page-container :show-title="false" title="상세조회" type="popup">
    <ur-box-container direction="column" alignV="start" class="ns-sch-area">

      <!-- 후보자명 -->
      <ur-box-container v-show="isSearchCust" alignV="start" componentid="ur_box_container_001" direction="column" class="sch-box">
        <mo-text-field v-model="searchKeyword" underline placeholder="후보자명을 입력하세요." maxlength="10" class="form-input-name"/>
      </ur-box-container>

      <!-- 조직관련 COMPONENT -->
      <SearchDetail ref="refSearchOrg" v-model="lv_SelectOrg" :isFofType="1" :pOrgData="pOrgData251P" :isShowFofOrg="isShowFofOrg" :isShowCnslt="false" @selectResult="fn_SlectCnsltResultRC"/>

      <!-- 실시월 As-Is: min, max 지정 안됨 -->
      <ur-box-container alignV="start" componentid="ur_box_container_002" direction="column" class="sch-box">
        <ur-text componentid="span_common_text_001" class="title">실시월</ur-text>
        <mo-month-picker v-model="efMnt" @confirmed="fn_ChangDate"/>
      </ur-box-container>

      <!-- 세미나명 -->
      <ur-box-container v-show="isSearchSemi" alignV="start" componentid="ur_box_container_002" direction="column" class="sch-box">
        <ur-text componentid="span_common_text_001" class="title">세미나명</ur-text>
          <msp-bottom-select :items="seminarGroups" class="ns-dropdown-sheet" v-model="selectSeminar" :disabled="!allChk" underline />
      </ur-box-container>

      <ur-box-container alignV="start" componentid="ur_box_container_005" direction="column" class="ns-btn-relative">
        <div class="relative-div">
          <mo-button componentid="mo_button_004" color="primary" shape="border" size="medium" class="ns-btn-round white" @click="fn_Clear">초기화</mo-button>
          <mo-button componentid="mo_button_005" color="primary" shape="border" size="medium" class="ns-btn-round blue" @click="fn_Close" :disabled="!allChk">조회</mo-button>
        </div>
      </ur-box-container>
    </ur-box-container>
  </ur-page-container>
</template>
<script>
  /***********************************************************************************
   * 공통 라이브러리 INCLUDE 영역	                                                   *
   ***********************************************************************************/
  import Screen from '~systems/mixin/screen'
  import moment from 'moment'
  import SearchDetail from '@/ui/rc/SearchDetail.vue'
  import MspBottomSelect from '@/ui/_cmm/components/MspBottomSelect.vue'

  export default {
    /***********************************************************************************
     * Vue 파일 속성 정의 영역	                                                       *
     ***********************************************************************************/
    name: 'MSPRC251P',
    screenId: 'MSPRC251P',
    mixins: [Screen],
    components: {
      MspBottomSelect,
      SearchDetail
    },
    props: {
      title: {
        type: String,
        default: '상세조회'
      },
      pSeminarYmdTm: {
        type: String,
        name: '세미나일정' // format: YYYYMMddHHmm
      },
      // 결과입력 / 결과조회
      pTabIdx: {
        type: String,
        default: '01'
      },
      // 세미나기준 / 참석후보자기준
      pSearchTabIdx: {
        type: String,
        default: '01'
      },
      pEfMntStr: {
        type: String,
        name: '세미나 실시월' // format: YYYYMM
      },
      isSearchCust: {
        type: Boolean,
        name: '결과조회-세미나기준',
        default: false
      },
      isSearchSemi: {
        type: Boolean,
        name: '결과조회-참석후보자기준',
        default: false
      },
      pOrgData250M: {
        type: Object,
        name: '세미나현황 메인 param',
      }
    },
    // bottom-sheet Full Popup
    modalProps: {
      mode: 'right' // 우->좌 open popup (defalut: 하->상)
    },
    /***********************************************************************************
     * 화면변수 선언 영역	                                                             *
     ***********************************************************************************/
    data() {
      return {
        lv_ReturnVal: {},

        rCSeminarVO: [],
        rCSemAttdListVO: [],
        orgRCSemAttdListVO: [],
        // -- 세미나 -- //
        seminarGroups: [{text:'선택', value:'0'}], // 세미나 리스트 Items
        selectSeminar: '0', // 선택된 세미나

        efMnt: '',  // 실시월(Array)
        efMntStr: '',        // 실시월(String)

        // -- 조직 컴포넌트 -- // 
        lv_SelectOrg: [], // 데이터
        selectedOrgData: {}, // 선택값

        hofOrgNo: '', // 사업부번호
        dofOrgNo: '', // 지역단조직번호
        fofOrgNo: '', // 지점조직번호

        // -- 참석현황 -- //
        semAtdncAplFgr: 0, // 세미나신청인원
        semAtdncFgr: 0, // 세미나참석인원
        semAtdncRt: 0, // 세미나참석률

        isOverTime: false,
        isShowFofOrg: false,
        isFirst: true,
        clearChk: false,

        semPlnYm: '', // 세미나월
        pOrgData251P: this.pOrgData250M,
        allChk: false, // 조직, 세미나명 조회완료 여부
        searchKeyword: ''
      }
    },
    /************************************************************************************************
     * Life Cycle 함수 정의 영역
     ************************************************************************************************/
    created() {
      // 사용자 정보
      this.userInfo = this.getStore('userInfo').getters.getUserInfo
      this.fn_Init()
    },
    /************************************************************************************************
    * Life Cycle 함수 정의 영역
    ***********************************************************************************************/
    mounted() {
      /** LOG 적재 Util **/
      this.$bizUtil.insSrnLog(this.$options.screenId)
    },
    /***********************************************************************************
     * 사용자 함수 정의 영역                                                           *
     ***********************************************************************************/
    methods: {
      /******************************************************************************
       * Function명 : fn_Init
       * 설명       : 최초 로딩 시 수행되는 함수
       ******************************************************************************/
      fn_Init() {
        if (this.pTabIdx === '02' && this.pSearchTabIdx === '01') {
          this.isShowFofOrg = false
        } else {
          this.isShowFofOrg = true    
        }
        this.fn_GetInitDate()
      },
      /******************************************************************************
       * Function명 : fn_ChangDate
       * 설명       : 달력컴포넌트 변경시
       ******************************************************************************/
      fn_ChangDate(param) {
        if (this.efMntStr !== moment(param).format('YYYYMM')) {
          this.efMntStr = moment(param).format('YYYYMM')
          this.isFirst = false
          // 세미나명 조회
          this.fn_SearchSeminar()
        }
      },
      /******************************************************************************
       * Function명 : fn_GetInitDate
       * 설명       : 초기 날짜 설정
       ******************************************************************************/
      fn_GetInitDate(isClear) {
        if (_.isEmpty(this.pEfMntStr) || isClear) { // isClear: 초기화 눌렀을때
          this.efMnt = moment(new Date()).format('YYYY-MM').split('-')
          this.efMntStr = this.efMnt[0]+this.efMnt[1]
        } else {
          this.efMntStr = this.pEfMntStr.substring(0,4)+this.pEfMntStr.substring(4,6)
          this.efMnt = [this.pEfMntStr.substring(0,4),this.pEfMntStr.substring(4,6)]
        }
      },
      /******************************************************************************
       * Function명 : fn_SearchSeminar
       * 설명       : 지역단 및 실시월 변경 시 세미나명 조회
       ******************************************************************************/
      fn_SearchSeminar() {
        // 세미나명 입력 없을경우
        if (!this.isSearchSemi) {
          this.allChk = true
          return
        } 

        const lv_Vm = this
        const trnstId = 'txTSSRC30S2'
        const auth = 'S'

        let pParams = {
          hofOrgNo: this.lv_SelectOrg.hofOrg.key, // 선택사업부
          dofOrgNo: this.lv_SelectOrg.dofOrg.key, // 선택지역단
          fofOrgNo: this.lv_SelectOrg.fofOrg.key, // 선택지점
          semPlnYm: this.efMntStr, // 세미나실시월
          semCloseYn: "Y" // 종료세미나포함여부
        }

        this.rCSeminarVO = []
        this.seminarGroups = [{text:'선택', value:'0'}]
        this.selectSeminar = '0'
        this.post(lv_Vm, pParams, trnstId, auth)
          .then(function (response) {
            if (!_.isEmpty(response.body) && !_.isEmpty(response.body.rCSeminarVO)) {
              lv_Vm.rCSeminarVO = response.body.rCSeminarVO

              // 세미나 리스트 셋팅 (Select Box)
              lv_Vm.fn_SetSeminarList(response.body.rCSeminarVO)
              lv_Vm.allChk = true
            } else {
              lv_Vm.allChk = false
              // lv_Vm.getStore('confirm').dispatch('ADD', response.msgComm.msgDesc)
            }
          })
          .catch(function (error) {
            window.vue.error(error)
            // 시스템적인 오류가 발생하면, 일반적인 에러 메세지를 confirm으로 표시
            if (!_.isEmpty(error.message)) {
              lv_Vm.getStore('confirm').dispatch('ADD', error.message)
            } else {
              lv_Vm.getStore('confirm').dispatch('ADD', lv_Vm.$t('rc')['ERCC001']) // 시스템 오류가 발생했습니다. \n관리자에게 문의하여 주세요.
            }

            lv_Vm.allChk = false
          })
      },
      /******************************************************************************
       * Function명 : fn_SlectCnsltResultRC
       * 설명       : 조직컴포넌트 변경시 세미나명 조회
       ******************************************************************************/
      fn_SlectCnsltResultRC(pData) {
        if (_.isEmpty(pData)) return

        this.selectedOrgData = pData
        this.lv_SelectOrg = pData
        this.semAtdncAplFgr = '0'
        this.semAtdncFgr = '0'
        this.semAtdncRt = '0'
        this.rCSemAttdListVO = []
        this.orgRCSemAttdListVO = []

        this.fn_SearchSeminar()
      },
      /******************************************************************************
       * Function명 : fn_SetReturnVal
       * 설명       : 팝업 닫힐때, 최종 return 입력값 셋팅
       ******************************************************************************/
      fn_SetReturnVal() {
        const lv_Vm = this
        if (_.isEmpty(this.selectedOrgData)) return

        let seminarNm = this.fn_GetSemiNm()

        let semiItem = this.rCSeminarVO.filter(item=>{
            if (item.semPlnYmd+item.semPlnTm === lv_Vm.selectSeminar) {
                return item
            }
        })

        if (!_.isEmpty(semiItem)) {
          this.lv_ReturnVal.semTypCd = semiItem[0].semTypCd
        }

        this.lv_ReturnVal = {
          tabIdx: this.pTabIdx,
          hofOrgCd: this.selectedOrgData.hofOrg.key, // 선택사업부
          dofOrgCd: this.selectedOrgData.dofOrg.key, // 선택지역단
          fofOrgCd: this.selectedOrgData.fofOrg.key, // 선택지점
          pOrgData: this.lv_SelectOrg,               // 조회 조직 데이터
          efMntStr : this.efMntStr,
          semNm: seminarNm,
          seminarYmdTm : this.selectSeminar,
          cndtrNm: this.searchKeyword.trim()
        }
      },
      /******************************************************************************
       * Function명 : fn_GetSemiNm
       * 설명       : 선택된 세미나명 getter
       ******************************************************************************/
      fn_GetSemiNm() {
        let seleSemiValu = this.selectSeminar
        let seleSeminarNm = ''

        this.seminarGroups.forEach(item => {
          if (seleSemiValu === item.value) {
            seleSeminarNm = item.text
          }
        })
        return seleSeminarNm
      },
      /******************************************************************************
       * Function명 : fn_Clear
       * 설명       : 입력값 모두 초기화 (초기 값으로 셋팅)
       ******************************************************************************/
      fn_Clear() {
        this.pOrgData251P = {}
        this.$refs.refSearchOrg.fn_GetFofRol() // 초기화
        this.fn_GetInitDate(true) // 실시월 초기화
        this.searchKeyword = ''
        this.fn_SearchSeminar()

      },
      /******************************************************************************
       * Function명 : fn_Close
       * 설명       : 팝업 닫기
       ******************************************************************************/
      fn_Close() {
        this.fn_SetReturnVal()
        this.$emit('onPopupSearch', this.lv_ReturnVal);
      },
      /******************************************************************************
       * Function명 : fn_SetSeminarList
       * 설명       : 세미나명 데이터 리스트 셋팅
       ******************************************************************************/
      fn_SetSeminarList(SeminarListVO) {
        const lv_Vm = this
        // 초기화
        this.seminarGroups = [{text:'선택', value:'0'}]
        this.selectSeminar = '0'
        if (_.isEmpty(SeminarListVO)) return
        SeminarListVO.forEach((item,idx) => {
          lv_Vm.seminarGroups[idx] = {value: item.semPlnYmd+item.semPlnTm, text: item.semNm}
        })
        
        // 최근 조회한 세미나로 셋팅
        if (!_.isEmpty(this.pSeminarYmdTm)) {
          this.selectSeminar = this.pSeminarYmdTm
        } else {
          this.selectSeminar = this.seminarGroups[0].value
        }
      }
    }
  };
</script>
